import appLinkSmallApple from '@common/shared/icons/mobile-store-icons/appLink_small_apple.svg';
import appLinkBigApple from '@common/shared/icons/mobile-store-icons/appLink_big_apple.svg';
import appLinkSmallGoogle from '@common/shared/icons/mobile-store-icons/appLink_small_google.svg';
import appLinkBigGoogle from '@common/shared/icons/mobile-store-icons/appLink_big_google.svg';
import appLinkSmallHuawei from '@common/shared/icons/mobile-store-icons/appLink_small_huawei.svg';
import appLinkBigHuawei from '@common/shared/icons/mobile-store-icons/appLink_big_huawei.svg';
import appLinkSmallRustore from '@common/shared/icons/mobile-store-icons/appLink_small_rustore.svg';
import appLinkBigRustore from '@common/shared/icons/mobile-store-icons/appLink_big_rustore.svg';
import { APPLE_STORE_URL, GOOGLE_STORE_URL, HUAWEI_STORE_URL, RUSTORE_STORE_URL } from '@common/shared/lib/links';
export const STORES = [
    {
        name: 'app-store',
        imgSmall: appLinkSmallApple,
        imgBig: appLinkBigApple,
        href: APPLE_STORE_URL,
        id: 'apple',
        label: 'Устанавливай VK music из App store',
    },
    {
        name: 'google-play',
        imgSmall: appLinkSmallGoogle,
        imgBig: appLinkBigGoogle,
        href: GOOGLE_STORE_URL,
        id: 'google',
        label: 'Устанавливай VK music из Google play',
    },
    {
        name: 'app-gallery',
        imgSmall: appLinkSmallHuawei,
        imgBig: appLinkBigHuawei,
        href: HUAWEI_STORE_URL,
        id: 'huawei',
        label: 'Устанавливай VK music из App gallery',
    },
    {
        name: 'rustore',
        imgSmall: appLinkSmallRustore,
        imgBig: appLinkBigRustore,
        href: RUSTORE_STORE_URL,
        id: 'rustore',
        label: 'Устанавливай VK music из Rustore',
    },
];
