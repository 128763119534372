import React from 'react';
import classNames from 'classnames';
import { analyticsDictionary, createAnalyticAttrs } from '@analytics';
import { FooterLogoWithErude, SupportLinksList } from 'common/shared/ui';
import { ContentLayout } from '../../layouts/content-layout';
import styles from './styles.module.scss';
const Footer = ({ wideContainer = false }) => {
    return (<footer className={styles.container}>
      <div className={styles.analyticBlock} {...createAnalyticAttrs(analyticsDictionary.landingFooterShow, ['show'])}/>
      <ContentLayout className={classNames({ [styles.footer__layout_wide]: wideContainer })}>
        <div className={styles.footer}>
          <SupportLinksList />
          <FooterLogoWithErude />
        </div>
      </ContentLayout>
    </footer>);
};
export default Footer;
