import classNames from 'classnames';
import { Footer } from '@website/shared/ui/organisms/footer';
import { Header } from '@website/widgets/header';
import style from './style.module.scss';
import { Seo } from '@website/shared/ui/molecules/seo';
const MainLayout = ({ children, title, className, metaTags, headerProps, wideContainer = false, mainLayoutClass, }) => {
    const hasMetaTags = Boolean(metaTags && metaTags.length > 0);
    const isRenderHead = hasMetaTags || Boolean(title);
    return (<div className={classNames(style.mainLayout, style.theme_music_dark, className)}>
      {isRenderHead && (<Seo title={title} metaTags={metaTags}/>)}
      <Header wideContainer={wideContainer} {...headerProps}/>
      <main className={classNames(style.mainLayout__main, mainLayoutClass)}>{children}</main>
      <Footer wideContainer={wideContainer}/>
    </div>);
};
export default MainLayout;
